function padZero (num) {
    return (num < 10 ? '0' : '') + num;
}
let utils = {
    formatDate: (data) => {
        let year = data.getFullYear();
        let month = padZero(data.getMonth() + 1);
        let day = padZero(data.getDate());
        let hours = padZero(data.getHours());
        let minutes = padZero(data.getMinutes());
        let seconds = padZero(data.getSeconds());

        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },

}
export default utils;

export function tranDate (time) {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，需要加 1，并补齐两位
    const day = date.getDate().toString().padStart(2, '0'); // 日
    const hour = date.getHours().toString().padStart(2, '0'); // 小时
    const minute = date.getMinutes().toString().padStart(2, '0'); // 分
    const second = date.getSeconds().toString().padStart(2, '0'); // 秒
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}