import Vue from 'vue'
import VueRouter from 'vue-router'
// /* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// // 先存储默认底层的push
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, resolve, reject) {
//   if (resolve || reject) return originalPush.call(this, location, resolve, reject)
//   return originalPush.call(this, location).catch((e) => { })
// }

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


Vue.use(VueRouter)
const personalRoutes = [
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import("@/view/userCenter/orderCenter/myOrder/myOrder.vue"),
  },
  {
    path: '/myCollection',
    name: 'myCollection',
    component: () => import("@/view/userCenter/orderCenter/myCollection/myCollection.vue"),
  },
  {
    path: '/myInquiry',
    name: 'myInquiry',
    component: () => import("@/view/userCenter/orderCenter/myInquiry/myInquiry.vue"),
  },
  {
    path: '/myPoints',
    name: 'myPoints',
    component: () => import("@/view/userCenter/propertyCenter/myPoints/myPoints.vue"),
  },
  {
    path: '/pointsDetails',
    name: 'pointsDetails',
    component: () => import("@/view/userCenter/propertyCenter/pointsDetails/pointsDetails.vue"),
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import("@/view/userCenter/propertyCenter/coupon/coupon.vue"),
  },
  {
    path: '/approvalOrder',
    name: 'approvalOrder',
    component: () => import("@/view/userCenter/approvalCenter/approvalOrder/approvalOrder.vue"),
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: () => import("@/view/userCenter/dataCenter/receipt/receipt.vue"),
  },
  {
    path: '/bill',
    name: 'bill',
    component: () => import("@/view/userCenter/dataCenter/bill/bill.vue"),
  },
  {
    path: '/modify',
    name: 'modify',
    component: () => import("@/view/userCenter/dataCenter/modify/modify.vue"),
  },
  {
    path: '/winRecord',
    name: 'winRecord',
    component: () => import("@/view/userCenter/activityCenter/winRecord/winRecord.vue"),
  }
]

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import("@/view/layout.vue"),
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import("@/view/index/index.vue"), //首页
      },
      {
        path: 'notice/:id?/:title?',
        name: 'notice',
        component: () => import("@/view/index/noticeInfo.vue"), // 首页公告
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import("@/view/brand/brand.vue"), // 品牌
      },
      {
        path: 'promotions',
        name: 'promotions',
        component: () => import("@/view/promotions/promotions.vue"), // 促销活动
      },
      {
        path: 'pointsMall',
        name: 'pointsMall',
        component: () => import("@/view/pointsMall/pointsMall.vue"), // 积分商城
      },
      {
        path: 'inquiry',
        name: 'inquiry',
        component: () => import("@/view/inquiry/inquiry.vue"), // 询价
      },
      {
        path: 'customized',
        name: 'customized',
        component: () => import("@/view/customized/customized.vue"), // 定制
      },
      {
        path: 'help/:id?/:cateCode?',
        name: 'help',
        component: () => import("@/view/help/help.vue"), // 帮助中心
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import("@/view/feedback/feedback.vue"), // 意见反馈
      },
      {
        path: 'productDetail/:id?',
        name: 'productDetail',
        component: () => import("@/view/index/productDetail.vue"), // 产品详情
      },
      {
        path: 'pointsInfo/:id?',
        name: 'pointsInfo',
        component: () => import("@/view/pointsMall/pointsInfo.vue"), // 积分详情
      },
      {
        path: 'pointsList/:id?',
        name: 'pointsList',
        component: () => import("@/view/pointsMall/pointsList.vue"), // 积分列表
      },
      {
        path: 'brandInfo/:id?',
        name: 'brandInfo',
        component: () => import("@/view/brand/brandInfo.vue"), // 品牌详情
      },
      {
        path: 'promotionInfo/:id?',
        name: 'promotionInfo',
        component: () => import("@/view/promotions/promotionInfo.vue"), // 品牌详情
      },
      {
        path: 'result/:cateId?/:keyword?/:manual?',
        name: 'result',
        component: () => import("@/view/result/result.vue")
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import("@/view/cart/cart.vue"), // 购物车列表
      },
      {
        path: 'form/:id?',
        name: 'form',
        component: () => import("@/view/cart/orderForm.vue"), // 填写订单
      },
      {
        path: 'orderDetail/:orderNo?',
        name: 'orderDetail',
        component: () => import("@/view/order/orderDetail.vue"), // 订单详情
      },
      // 找回密码
      {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/view/user/forgot/forgot.vue'),
      },
      {
        path: '/singlePage/:current?',
        name: 'singlePage',
        component: () => import('@/view/single/singlePage.vue'),
      },
      {
        path: '/coaPage/:current?',
        name: 'coaPage',
        component: () => import('@/view/single/coaPage.vue'),
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/view/userCenter/personal.vue'),
        redirect: '/myOrder',
        children: [
          ...personalRoutes
        ]
      }
    ],
  }, {
    path: '/login',
    name: 'login',
    component: () => import('@/view/user/login/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/view/user/register/register.vue'),
  },
]

// history模式
// const createRouter = () => new VueRouter({
//   mode: 'history',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: [...routes]
// })
// const router = createRouter()

// hash模式
const router = new VueRouter({
  routes
})

// 设置全局前置守卫
// router.beforeEach((to, from, next) => {
//   let token = Store.state.sdawdascafawdfaw
//   if (to.path == "/mine") {
//     if (token) {
//       next()
//     } else {
//       if (to.path == "/") {
//         next()
//       }
//       next("/")
//     }
//   }
// })
router.afterEach(() => {
  window.scrollTo(0, 0);
})
export default router
