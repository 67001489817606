import axios from 'axios';
import { Message } from "element-ui"
import store from "@/store/index"
// 封装服务器地址
axios.defaults.baseURL = 'https://fangdartech.top/api/'
// axios.defaults.baseURL = 'http://43.139.121.71:2015/api'

axios.defaults.timeout = 10000

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    const token = store.state.token
    let data = { token: token }
    config.data = { ...config.data, ...data }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么 (数据剥离)
    let { data } = response
    // 错误代码
    if (data.code === 3) {
        Message({
            type: "error",
            message: '您还未登录，请先登录'
        })
    } else if (data.code !== 1 && data.code !== 3) {
        Message({
            type: "error",
            message: data.msg
        })
        return new Promise(() => { })
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// 暴露axios
export default axios