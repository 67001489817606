import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
import { getUserInfoAPI, postLoginAPI } from '@/API/user'
import { getSettingAPI } from '@/API/index';
import { Message } from "element-ui"
Vue.use(Vuex)

// 本地存储要加密
// let ls = new SecureLS({ 
//   encodingType: "aes",    //加密类型
//   isCompression: false,
//   encryptionSecret: "encryption",   //PBKDF2值
// });
// 查询本地存储如何加密

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
    orderList: [],//订单
    setting: {},  // 项目配置
    newLoginForm: {
      phone: '',
      password: ''
    },
    xunjiaForm: {},
    dingzhiForm: {
      name: '',//化合物名称
      casno: '',//CasNo编号
      num: '',//需求量
      chundu: '',//产品纯度
      enddate: '',//交货期
      jiegou_image: [], //产品结构图
      wenxian_image: [],//文献资料
      realname: '',//姓名
      email: '',//email
      phone: '',//联系人手机号
      company: '',//单位名称
      veritycode: '',//验证码
      note: '',//备注
    }
  },
  getters: {
  },
  mutations: {
    // 本地存储一次token
    SET_TOKEN (state, newToken) {
      state.token = newToken
    },
    // 清除本地token
    REMOVE_TOKEN (state) {
      state.token = null
    },
    // 存储用户信息
    SET_USER_INFO (state, payload) {
      state.userInfo = payload
    },
    // 存储订单
    SET_ORDER_LIST (state, payload) {
      state.orderList = payload
    },
    // 存储配置信息
    SET_SETTING (state, payload) {
      state.setting = payload
    },
    // 存储新的登录信息
    SET_FORM (state, payload) {
      state.newLoginForm = payload
    },
    // 存储询价单
    SET_XUNJIAFORM (state, payload) {
      state.xunjiaForm = payload
    },
    // 存储定制单
    SET_DINGZHIFORM (state, payload) {
      state.dingzhiForm = payload
    }
  },
  actions: {
    // 登录请求
    async login (context, data) {
      const res = await postLoginAPI(data)
      // console.log(res)
      if (res.code === 1) {
        context.commit('SET_TOKEN', res.data.token)
        // 发起请求获取用户信息
        const res1 = await getUserInfoAPI()
        context.commit('SET_USER_INFO', res1.data)
        Message({
          type: "success",
          message: '登录成功'
        })
        // 路由跳转
        setTimeout(() => {
          router.push('/index')
        }, 1000)
      } else if (res.code === 0) {
        Message({
          type: "error",
          message: '验证码输入错误'
        })
      }
    },
    // 退出登录
    async logout (context) {
      // 清除token
      context.commit('REMOVE_TOKEN')
      // 清空用户信息
      context.commit('SET_USER_INFO', {})
      // 回到首页
      setTimeout(() => {
        router.push('/index')
      }, 1000)
    },
    // 获取配置信息
    async getSetting (context) {
      const res = await getSettingAPI()
      context.commit('SET_SETTING', res.data)
    },
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "huaxuepin_user_persisted",
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: (key) => ls.remove(key),
      // },
    }),
  ], // vuex持久化
})
