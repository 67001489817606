import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipBoard from 'vue-clipboard2'
import '@/utils/rem.js'
import '@/assets/css/element-variables.scss'
import utils from '@/utils/index'
import VueLazyload from 'vue-lazyload'

// 重置样式
import '@/assets/css/normalize.css'
import '@/assets/css/reset.css'
import '@/assets/iconfont/iconfont.css'
// 导入组件
Vue.config.productionTip = false
Vue.prototype.$utils = utils

// 配置项
Vue.use(VueLazyload, {
  // error: require('../src/assets/image/error.png'),
  // loading: require('../src/assets/image/loading.gif'),
  attempt: 1
})

// img元素上使用v-lazy="src";<img v-lazy="showImage"/>

Vue.use(VueClipBoard)
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
