import request from "@/utils/request";

// 获取用户信息
export const getUserInfoAPI = (data) => {
  return request.post('/member/index', data)
}

// 收藏/取消收藏
export const handleCollectAPI = (data) => {
  return request.post('/member/add_collect', data)
}

// 获取收藏列表
export const getCollectListAPI = (data) => {
  return request.post('/member/get_collect_list', data)
}

// 获取积分兑换明细列表
export const getJifenListAPI = (data) => {
  return request.post('/member/get_jifen_list', data)
}

// 修改密码
export const updatePasswordAPI = (data) => {
  return request.post('/member/update_password', data)
}

// 我的询价列表
export const getXunjiaListAPI = (data) => {
  return request.post('/member/get_xunjia_list', data)
}

// 上传文件
export const uploadFileAPI = (data) => {
  return request.post('/common/upload', data)
}

// 定制
export const postDingzhiAPI = (data) => {
  return request.post('/member/dinzhi', data)
}

// 获取短信验证码
export const getCodeAPI = (data) => {
  return request.post('/index/send_sms', data)
}

// 注册
export const postRegisterAPI = (data) => {
  return request.post('/index/register', data)
}

// 登录
export const postLoginAPI = (data) => {
  return request.post('/index/login', data)
}

// 找回密码
export const getPasswordAPI = (data) => {
  return request.post('/index/zhaohui_password', data)
}
// 购物车结算
export const postJiesuanAPI = (data) => {
  return request.post('/member/jiesuan', data)
}

// 提交订单
export const postOrderAPI = (data) => {
  return request.post('/member/add_order', data)
}

// 获取积分明细
export const huoJifenListAPI = (data) => {
  return request.post('/member/get_jifenhuoqu_list', data)
}

// 发票信息列表
export const getFapiaoListAPI = (data) => {
  return request.post('/member/get_fapiao_list', data)
}

// 打印 fetch请求
// export const getDayinAPI = (data) => {
//   return request.post('/member/daying', data)
// }

// 订单审核
export const getShenheListAPI = (data) => {
  return request.post('/member/get_shenhe', data)
}

//申请开票
export const getApplyInvoiceAPI = (data) => {
  return request.post('/member/set_kaipiao', data)
}

//发票详情
export const getInvoiceDetailAPI = (data) => {
  return request.post('/member/get_yonghufapiao_info', data)
}



