// setRem();
// window.addEventListener("resize", setRem); // 窗口大小改变时重新计算
function setRem () {
  //  PC端
  // console.log('非移动设备')
  const baseSize = 16 // 基准值

  // 相对于1920像素的缩放比 
  let scale = document.documentElement.clientWidth / 1536
  // 根据屏幕变化 1rem 对应的 font-size
  scale = scale > 1 ? 1 : scale;
  const realFont = baseSize * scale
  document.documentElement.style.fontSize = realFont + 'px'
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
};
