<template>
  <div id="app">
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  created () {
    this.$store.dispatch('getSetting')
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  main {
    height: 100%;
  }
}
</style>
