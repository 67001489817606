import request from "@/utils/request";

// 品牌中心
export const getBrandListAPI = () => {
  return request.post('/index/get_pinapi_list')
}
// 品牌详情
export const getBrandDetailAPI = (data) => {
  return request.post('/index/get_pinpai_info', data)
}
// 促销活动列表
export const getActiveListAPI = (data) => {
  return request.post('/index/get_cuxiao_list', data)
}
// 促销活动详情
export const getActiveInfoAPI = (data) => {
  return request.post('/index/get_cuxiao_info', data)
}
// 化学品列表
export const getProductsListAPI = (data) => {
  return request.post('/index/get_products_list', data)
}
// 化学品列表条件
export const getProductsSortAPI = (data) => {
  return request.post('/index/get_tiaojian_list', data)
}

// 首页每周特价
export const getTejiaProductsAPI = (data) => {
  return request.post('/index/get_products_meizhou', data)
}

// 首页服务通知列表
export const getNoticeListAPI = (data) => {
  return request.post('/index/get_notice_list', data)
}

// 首页服务通知详情
export const getNoticeInfoAPI = (data) => {
  return request.post('/index/get_notice_info', data)
}

// 帮助中心
export const getArticleListAPI = (data) => {
  return request.post('/index/get_articles_list', data)
}

// 隐私协议
export const getZhengceListAPI = (data) => {
  return request.post('/index/get_zhence_list', data)
}

// 文章详情
export const getArticleInfoAPI = (data) => {
  return request.post('/index/get_articles_info', data)
}

// 首页关键字
export const getGuanjianziAPI = () => {
  return request.post('/index/get_guanjianzi_list')
}

// 配置接口
export const getSettingAPI = () => {
  return request.post('/index/get_setting')
}

// 关于我的
export const getAboutUsAPI = (data) => {
  return request.post('/index/get_women_list', data)
}

// 查看物流
export const getWuliuInfoAPI = (data) => {
  return request.post('/member/get_wuliu_info', data)
}

// 查看coa
export const getCOAAPI = (data) => {
  return request.post('/index/get_coa', data)
}
